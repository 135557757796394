.sidebar {
    z-index: 1023;
    background-color : #fff;
    min-height: 100vh;
    width: 220px;
    /*border-right: 1px solid #000;*/
}
.pointer{
    cursor:pointer;
}

.react-datepicker-wrapper {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}



.admin_main_header {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    width: 225px; /* Set the width of the sidebar */
    position: fixed;
    height: 100vh;
    background-color: #fff;
}

.admin_classtableheader th {
    background-color: var(--bg-light);
    color: var(--text);
    cursor: pointer;
    text-align: start;
    padding: 5px 14px !important;
}
.admin_classtableheader td {
    padding: 3px 10px !important;
    font-size: 13px !important;
    border-style: solid;
    width: 50px;
}

.admin_classtable_heading {
    white-space: nowrap;
    text-align: start;
    font-size: 13px;
}

.admin_classmain-card {
    background-color: var(--main-card);
    border-radius: 8px;
    padding: 10px;
    box-shadow: var(--shadow);
}




.admin_classsidebar-nav .admin_classnav-link {
    align-items: center;
    background: #000;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    padding: 7px 15px;
    transition: all .3s;
    white-space: nowrap;
}
.admin_classsidebar-nav .admin_classnav-content {
    list-style: none;
    margin: 2px 0 0;
    padding: 3px 0 0;
    white-space: nowrap;
}

    .admin_classsidebar-nav .admin_classnav-content a {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        padding: 4px 0 2px 19px !important;
        transition: .3s;
        white-space: nowrap;
    }
.sidebar-submenu a {
    color: var(--text) !important;
    white-space: nowrap;
}
admin_classsidebar-nav, .admin_classsidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
    white-space: nowrap;
}


.admin_form-control {
    padding: 6px 12px;
    font-size: 12px;
    border-radius: 12px;
}

.admin_mybtn{
    padding : 5px 13px !important;
    font-size: 12px;
}

.hideshowbtn {
    padding: 5px 13px !important;
    font-size: 12px;
}
.hideshowbtninside {
    padding: 8px 20px;
    border-radius: 12px;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    background: rgb(255, 255, 255);
    color: #fff!important;
    border: 0;
}
.secondary-bghideshowbtn {
    background-color: #4E2D87;
    opacity:0.8;
    margin-top:3px!important;
}
.headerbg {
    background-color: #FFFFFF !important;
    border-radius: 5px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
}




/*  Header  */

.css-1x7skt0 {
    background-color: #fff !important;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #fff !important;
    color: #000 !important;
}

.mui_Admin .MuiInputBase-input {
    padding: 8px 12px !important;
    font-size: 12px;
}
.mui_Admin .MuiInputBase-root {
    border-radius: 12px;
    padding: 0 !important;
}
.Admin_hoverside {
    transition: background-color 0.3s ease; 
}

    .Admin_hoverside:hover {
        background-color: rgba(78, 45, 135, 0.8);
        color:white;
        border-radius:5px
    }
    .Admin_hoverside .active {
        color: #5d05f5 !important;
    }
.admin_left_panel{
    margin-left: 225px;
    padding: 10px 20px;
}
.Admin_loginhover:hover {
    background-color: rgba(78, 45, 135, 0.9) !important;
}
.MuiAutocomplete-root fieldset {
    border-color: #ced4da !important;
    border-width: 1px !important;
}

.MuiAutocomplete-root .Mui-focused fieldset {
    border-color: #4e2d87 !important;
    background-color: #ffffff17 !important;
}
.filters .search {
    border-radius: 5px 0 0 5px !important;
    border-right: 0px !important;
}

.filters .search-btn {
    height: 26px;
    border-radius: 0 5px 5px 0;
    padding: 1px 10px;
    width: 35px;
    min-width: 35px;
    background-color: #4E2D87 !important;
    margin: 0 8px 0 0 !important;
    color: #fff;
}

.filters .advance-search-btn {
    height: 22px;
    border-radius: 5px;
    width: 35px;
    padding: 1px 10px;
    min-width: 35px;
    background-color: var(--first_theme);
    margin: 0 8px 0 0 !important;
    color: #fff;
}
.button-disabledOrder {
    background-color: #e0e0e0;
    color: #a0a0a0;
    cursor: not-allowed;
    opacity: 0.6;
}


/*Mini Loader*/
.mini_loader {
    border-radius: 50%;
    background: radial-gradient(farthest-side, #dbcccc 94%, #0000) top / 8px 8px no-repeat, conic-gradient(#0000 10%, #dbcccc);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    100% {
        transform: rotate(1turn)
    }
}

@keyframes shakeicon {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-8px);
    }

    100% {
        transform: translateY(0);
    }
}




/*fileuplaoder*/
.file-uploader {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-width: 100% !important;
    max-width: 508px !important;
    height: 30px !important;
    margin-top: 0px !important;
    border: 2px dotted #4E2D87 !important;
    padding: 3px 5px !important;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-flex: 0;
    flex-grow: 0;
}

    .file-uploader > svg {
        width: 20px !important;
        height: 20px !important;
    }

    .file-uploader span {
        margin-left: 5px;
        /*font-size: 10px !important;*/
        color: black !important;
    }


    .file-uploader .file-children {
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;
    }

        /*.file-uploader .file-types {
        color: var(--text) !important;
    }*/

        .file-uploader .file-children .sc-fqkvVR span:first-child {
            margin-left: 5px;
            margin-right: 5px;
            /*font-size: 10px !important;*/
            color:black !important;
            text-decoration: underline;
        }



/* Style the scrollbar track (the part the scrollbar moves along) */
.table-responsive::-webkit-scrollbar {
    width: 19px;
    height: 8px; 
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: rgb(78, 45, 135); 
    border-radius: 6px; 
}

.table-responsive::-webkit-scrollbar-thumb:hover {
        background-color: rgb(123 91 179); 
}

.table-responsive::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
    border-radius: 6px;
}

/*SearchBarSearchBarSearchBarSearchBar*/

.srch_box {
    width: 100%;
    background-color: #fff;
    border: 3px solid #f3f3f3;
    border-radius: 11px !important;
    transition: all .3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
}

.srch_box_item {
    padding: 7px 10px;
    transition: all .2s ease-in;
}

    .srch_box_item:hover {
        background-color: #e4e4e4;
    }

    .srch_box_item.active {
        background-color: #e4e4e4;
    }

.css-148fdm8:hover {
    background-color: transparent !important;
}
.css-av538e-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: transparent !important;
}

.slick-next:before{
    background-color : var(--primary) !important;
    padding-top : 2px;
}
.slick-prev:before {
    background-color: var(--primary) !important;
    padding-top: 2px;
}

.muiModal-close-btn {
    background-color: rgb(121 72 203);
    border: none;
    color: #fff;
    border-radius: 4px;
    padding: 1px 8px;
}
.ntwrk_oprtr{
    text-align:right;
    font-size:12px;
    cursor:pointer;
}
.ntwrk{
    max-width:225px;
}
.admin_upload_xlsx {
    border: 2px dashed rgb(128 128 128);
    padding: 15px;
    border-radius: 12px;
    text-align: center;
    min-width: 280px;
}
.admin_upload_xlsx span{
    font-size:12px;
}
.commission{
    background-color: #fff9e8;
}
.commission .cum-wrap .cum-box{
    background-color: #ffffff;
    border-radius: 12px;
    padding: 15px; 
    width: 40%;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.commission .cum-wrap .cum-box:nth-child(1), .commission .cum-wrap .cum-box:nth-child(3){
    width: 30%;
}
.commission .cum-wrap .cum-box h6{
    white-space: nowrap;
    margin-bottom: 10px;
    background-color: var(--primary);
    padding:10px 5px;
    color: #fff;
    border-radius: 5px;
}
.color_box{
    width: 30px;
    height: 15px;
    border-radius: 5px;
}